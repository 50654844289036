<template>
  <dialog ref="dialog" @click="$emit('close')">
    <AuthenticateForm @click.stop :mode="mode" />
  </dialog>
</template>
<script setup lang="ts">
defineEmits(["close"]);
defineProps<{
  mode?: "register" | "login";
}>();

const dialog = ref<HTMLDialogElement>();
onMounted(() => dialog.value?.showModal());
</script>
<style scoped>
dialog {
  border-radius: var(--border-radius);
  border: var(--border);
  box-shadow: var(--prominent-shadow);
}

dialog::backdrop {
  background: rgba(231, 230, 226, 0.4);
}
</style>
