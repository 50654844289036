<template>
  <footer>
    <Logo />
    <ul>
      <li><NuxtLink to="/">Home</NuxtLink></li>
      <li><NuxtLink to="/about/explore-courses">Explore Courses</NuxtLink></li>
      <li><NuxtLink to="/?signup">Signup</NuxtLink></li>
      <li><NuxtLink to="/?login">Login</NuxtLink></li>
      <li><NuxtLink to="/privacy">Privacy Policy</NuxtLink></li>
    </ul>
  </footer>
</template>

<style scoped>
footer {
  /* Box properties */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--grid-2);
  margin: 0;
  margin-top: var(--grid-2);
  width: auto;
  /* Background & borders */
  background-color: var(--black);
  background: url("~/assets/bg-topo-dark.png") center repeat;
  background-size: 500px 500px;
  /* Typography */
  color: var(--off-white);
  /* Animation */
  /* Other */
}

footer > * {
  /* Box properties */
  flex-grow: 1;
  padding: 0 var(--grid);
  margin: 0;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

footer h3 {
  /* Box properties */
  width: 0;
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

footer ul {
  /* Box properties */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  /* Background & borders */
  /* Typography */
  list-style: none;
  font-family: var(--title-family);
  font-weight: 600;
  font-size: var(--font-size-small);
  /* Animation */
  /* Other */
}

footer ul li {
  /* Box properties */
  /* Background & borders */
  /* Typography */
  /* Animation */
  /* Other */
}

footer ul li a {
  /* Box properties */
  display: block;
  padding: 0rem 0rem 0.5rem 2rem;
  margin: 0;
  /* Background & borders */
  border-left: var(--border-dark);
  background-color: rgba(red, 0);
  /* Typography */
  color: var(--medium-gray);
  /* Animation */
  transition: var(--transition);
  /* Other */
}

footer ul li a:hover {
  /* Box properties */
  /* Background & borders */
  border-color: var(--brand);
  /* Typography */
  color: var(--brand);
  /* Animation */
  /* Other */
}

nav ul li a.button {
  /* Box properties */
  /* Background & borders */
  margin: 0;
  /* Typography */
  /* Animation */
  /* Other */
}
</style>
