<template>
  <div class="marketing-root">
    <Header />
    <div class="content">
      <slot />
    </div>
    <AuthenticateModal
      v-if="$route.query.signup === null || $route.query.login === null"
      @close="$router.replace({ query: {} })"
      :mode="$route.query.signup === null ? 'register' : 'login'"
    />
    <Footer />
  </div>
</template>
<style>
@import "~/assets/marketing-base.css";
</style>
